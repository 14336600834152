<template>
  <div class="onboarding u__background--white">
    <Content>
      <FlexContainer
        v-show="step === 0"
        padding="normal"
        class="onboarding__item"
      >
        <img
          class="onboarding__image"
          :src="require('@/assets/images/onboarding/planet.svg')"
        />
        <FlexItem padding="normal" :grow="true" class="onboarding__content">
          <h2>Our pledge<br />to the planet</h2>
          <p class="large">
            As a business, we take our impact on the planet very seriously.
          </p>
          <p>
            There are many things we can do to reduce our carbon emissions, but
            where do we start? We've created a number of journeys that will help
            each and every one us reduce our impact. The first journey is about
            the energy we use when working from home and commuting: The Hybrid
            working journey.
          </p>
          <p>This is where you can help.</p>
          <Button type="transparent" :on-click="() => nextStep(1)">Next</Button>
        </FlexItem>
      </FlexContainer>
      <FlexContainer
        v-show="step === 1"
        padding="normal"
        class="onboarding__item"
      >
        <img
          class="onboarding__image"
          :src="require('@/assets/images/onboarding/measure.svg')"
        />
        <FlexItem padding="normal" :grow="true" class="onboarding__content">
          <h2>Measure<br />and reduce</h2>
          <p class="large">
            To reduce our impact, we first need to know where we are now.
          </p>
          <p>
            For our organisation to make a real difference, we need your help to
            create an energy snapshot. The energy you use when you're working
            from home and when you travel to work. With this information we can
            give you ways to reduce your impact, earn rewards and save some
            money.
          </p>
          <Button type="transparent" :on-click="() => nextStep(2)">Next</Button>
        </FlexItem>
      </FlexContainer>
      <FlexContainer
        v-show="step === 2"
        padding="normal"
        class="onboarding__item"
      >
        <img
          class="onboarding__image"
          :src="require('@/assets/images/onboarding/goal.svg')"
        />
        <FlexItem padding="normal" :grow="true" class="onboarding__content">
          <h2>Rewards &amp; recognition</h2>
          <p class="large">
            Through education, incentives and a sense of community.
          </p>
          <p>
            As you progress through the journey, contribute ideas and compete in
            team challenges you will earn points to redeem with our corporate
            incentive scheme. You'll also be able to claim tax relief and access
            some fantastic offers from our energy and travel partners.
          </p>
          <Button :on-click="completeOnboarding" :loading="loading"
            >Let's begin</Button
          >
        </FlexItem>
      </FlexContainer>
      <ul class="onboarding__navigation">
        <li :class="{ active: step === 0 }"></li>
        <li :class="{ active: step === 1 }"></li>
        <li :class="{ active: step === 2 }"></li>
      </ul>
      <FlexContainer padding="normal" justify-content="center">
        <FlexItem padding="normal" :grow="true" text-align="center">
          <a class="onboarding__skip" @click="completeOnboarding">Skip this</a>
        </FlexItem>
      </FlexContainer>
    </Content>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: function() {
    return {
      step: 0,
      loading: false
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser
    })
  },
  mounted() {
    if (this.currentUser && this.currentUser.hasOnboarded) {
      this.$router.push('/')
    }
  },
  methods: {
    ...mapActions({
      onboardUser: 'user/onboardUser'
    }),
    nextStep(step) {
      if (step < 0 || step > 2) {
        return
      }

      this.step = step
    },
    async completeOnboarding() {
      this.loading = true
      this.onboardUser({ id: this.currentUser.id, hasOnboarded: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.onboarding {
  position: absolute;
  z-index: 3;
  top: 60px;
  height: calc(100vh - 60px);
}

.onboarding__item {
  position: relative;
}

.onboarding__image {
  position: absolute;
  right: 0;
  z-index: 0;
  width: 250px;
}

.onboarding__content {
  position: relative;
  z-index: 1;
  padding-top: 180px;
  line-height: 1.1;

  h2 {
    font-size: 3.6rem;
    margin-bottom: 2.4rem;
  }

  p {
    font-size: 1.6rem;

    &:last-of-type {
      margin-bottom: $spacing-l;
    }

    &.large {
      font-size: 1.8rem;
    }
  }
}

.onboarding__navigation {
  display: flex;
  justify-content: center;

  li {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background-color: $color-gray;
    background-clip: content-box;
    border: 0.25rem solid transparent;
    border-radius: 50%;
    font-size: 0;
    transition: transform 0.1s;

    &.active {
      background-color: $color-secondary;
    }
  }
}

.onboarding__skip {
  margin: auto;
  font-size: 14px;
  color: $color-gray--dark;
}
</style>
